import { API } from "constants/envs"

export const getConfig = async () => {
  let url = `${API}/config/`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}

export const putConfig = async (payload: any) => {
  let url = `${API}/config/`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body: JSON.stringify(payload)
  });

  const info = response.json();
  return info;
}