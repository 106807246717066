import { useLayoutEffect, useRef } from 'react'
import { IModal } from './props'
import { Body, Content, Header, Hr, ModalComponent, Title } from './styles'

const Modal = ({
  open,
  onClose,
  title,
  children,
  closeInClickOut,
  style,
}: IModal) => {
  const refContent = useRef<HTMLDivElement>(null)

  const onKeyDown = (e: KeyboardEvent) => {
    const isEscape = e.key === 'Escape'

    if (isEscape) onClose()
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!closeInClickOut) return

    const isClickRight = e.button === 0
    const isOut = !(refContent?.current?.contains(e.target as Element))

    if (isClickRight && isOut) onClose()
  }

  useLayoutEffect(() => {
    if (open && closeInClickOut) {
      document.addEventListener('keydown', onKeyDown)
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.body.style.overflow = 'visible'
    }
  }, [open])

  if (!open) return null

  return (
    <ModalComponent
      onMouseDown={onMouseDown}
    >
      <Content
        ref={refContent}
        style={style}>
        <Header>
          <Title>{title}</Title>
          <svg
            onClick={onClose}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className='cursor-pointer'
            width={20}
            height={20}>
            <path fill="#454545" d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z" />
          </svg>
          <Hr />
        </Header>

        <Body>
          {children}
        </Body>
      </Content>
    </ModalComponent >
  )
}

Modal.defaultProps = {
  title: '',
  closeInClickOut: true,
}

export default Modal