import { API } from "constants/envs";

export const getPayment = async (id: number) => {
  let url = `${API}/mp/details_payment?id=${id}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}