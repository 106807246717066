import * as colors from 'constants/colors';
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin: 2rem auto;
`;

export const Title = styled.h3`
  font-size: 1.1rem;
  color: ${colors.primary01};
`

export const List = styled.ul`
  display: grid;
  gap: 0.2rem;
  margin: 0.5em 0 0 0;
  width: 100%;
`

export const ItemList = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  p{
    margin: 0 !important;
  }
`