import { useAuth } from 'auth/AuthContext'
import { Loading } from 'components'
import * as links from 'constants/links'
import * as pages from 'pages'
import { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import Route from './Route'

const Routes = () => {
  const [loading, setLoading] = useState(false)
  const { loginWithToken } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      loginWithToken(setLoading)
    }
  }, [])

  return (
    <>
      {
        loading ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading />}>
            <BrowserRouter>
              <Switch>
                <Route isAuth path={links.login} component={pages.Login} />
                <Route isPrivate path={links.dashboard} component={pages.Dashboard} />
                <Route isPrivate path={links.process} component={pages.Process} />
                <Route isPrivate path={links.pay} component={pages.Pay} />
                <Route isPrivate path='*' component={pages.Dashboard} />
              </Switch>
            </BrowserRouter>
          </Suspense>
        )
      }
    </>
  )
}

export default Routes
