import { API } from "constants/envs"

export const getStudentPaging = async (
  page: number,
  filters: { search: string, startDate: string, endDate: string, origin: string, school?: string }
) => {
  let url = `${API}/user/list?page=${page}&search=${filters.search}`;

  if (filters.startDate) {
    const date = new Date(filters.startDate)
    const dateISO = date.toISOString()
    const startDate = dateISO.split('T')[0] + 'T05:00:00.000Z'

    url += `&startDate=${startDate}`
  }

  if (filters.endDate) {
    const endDate = new Date(filters.endDate)
    endDate.setDate(endDate.getDate() + 1)
    endDate.setHours(23, 59, 59)
    const dateISO = endDate.toISOString()

    url += `&endDate=${dateISO}`
  }

  if (filters.origin) {
    url += `&origin=${filters.origin}`
  }

  if(filters.school){
    url += `&school=${filters.school}`
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
};

export const getStudents = async (
  filters: { search: string, startDate: string, endDate: string, origin: string, school: string }
) => {
  let url = `${API}/user/list?all=1`;

  if (filters.startDate) {
    const date = new Date(filters.startDate)
    const dateISO = date.toISOString()
    const startDate = dateISO.split('T')[0] + 'T05:00:00.000Z'

    url += `&startDate=${startDate}`
  }

  if (filters.endDate) {
    const endDate = new Date(filters.endDate)
    endDate.setDate(endDate.getDate() + 1)
    endDate.setHours(23, 59, 59)
    const dateISO = endDate.toISOString()

    url += `&endDate=${dateISO}`
  }

  if (filters.origin) {
    url += `&origin=${filters.origin}`
  }

  if(filters.school){
    url += `&school=${filters.school}`
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
};

export const getSchools = async () => {
  const url = `${API}/schools/`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
    },
  });

  const info = response.json();
  return info;
}

export const deleteSchool = async (id: number) => {
  const url = `${API}/schools/`;

  const response = await fetch(url, {
    method: "DELETE",
    body: JSON.stringify({ id }),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}

export const createSchool = async (name: string) => {
  const url = `${API}/schools/`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ name }),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}