import { Toast } from 'components'
import { getConfig, putConfig } from './api'

export const listConfig = async (
  setLoading: Function,
  setConfig: Function,
) => {
  try {
    setLoading(true)
    const response = await getConfig()

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    setConfig(response.config)
  } catch (error: any) {
    if (error.message === 'Token inválido') {
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: 'No se pudo obtener la lista, intente nuevamente'
      })
    }
  } finally {
    setLoading(false)
  }
}

export const updateConfig = async (
  setLoading: Function,
  payload: any,
  callback: Function,
) => {
  try{
    setLoading(true)

    const response = await putConfig(payload)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    Toast({
      type: 'success',
      message: 'Configuración actualizada'
    })
    callback()
  } catch (error: any) {
    if (error.message === 'Token inválido') {
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: 'No se pudo actualizar la configuración, intente nuevamente'
      })
    }
  } finally {
    setLoading(false)
  }
}