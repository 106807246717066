import AuthProvider from 'auth/AuthContext';
import { Layout } from 'components';
import Routes from 'routes';

const App = () => {
  return (
    <AuthProvider>
      <Layout>
        <Routes />
      </Layout>
    </AuthProvider>
  )
}

export default App
