import icon01 from 'images/icon01.png';
import icon02 from 'images/icon02.png';
import icon03 from 'images/icon03.png';
import icon04 from 'images/icon04.png';
import icon05 from 'images/icon05.png';
import icon06 from 'images/icon06.png';
import icon07 from 'images/icon07.png';
import icon08 from 'images/icon08.png';
import icon09 from 'images/icon09.png';
import icon10 from 'images/icon10.png';
import icon11 from 'images/icon11.png';
import icon12 from 'images/icon12.png';
import icon13 from 'images/icon13.png';
import icon14 from 'images/icon14.png';
import icon15 from 'images/icon15.png';
import { ITest } from "interfaces";

const TEST: ITest[] = [
  {
    id: 'campo1',
    name: "Campo 1",
    carrer: 'Científico',
    icon: icon01,
    questions: [
      '1.	Estudiar los seres vivos y realizar trabajos en campo como la botánica, la zoología o la genética.',
      '2.	Trabajar en centros de cultivo de especies acuáticas, vegetales o animales.',
      '3.	Ser geólogo (a).',
      '4.	Ser licenciado(a) en ciencias del mar.',
      '5.	Estudiar los fenómenos del firmamento y aplicar estos conocimientos a la navegación o a la utilización del espacio.',
      '6.	Investigar las propiedades de la materia para los productos de medicina, agricultura y otras.',
      '7.	Ser químico.',
      '8.	Ser biólogo(a).',
      '9.	Estudiar la tierra y sus componentes.',
      '10. Dedicarse a la conservación de recursos naturales.'
    ],
    results: [
      'Este campo se relaciona con el interés por estudiar los seres vivos, la tierra y sus componentes, así como los fenómenos del firmamento. Las personas con intereses altos en este campo muestran interés por las propiedades de la materia y su aplicación en la medicina, agricultura, alimentación, así como en la investigación de la energía y su relación entre ésta y la materia expresada en términos matemáticos, entre otros. A la vez, este campo es característico por el interés en la aplicación de conocimientos científicos relacionados con el medio ambiente y la conservación de recursos naturales. En términos generales, interés por estudiar materias básicas para la investigación de fenómenos naturales. '
    ],
    suggestionsLabel: 'Algunas de las carreras incluidas en este campo son: ',
    suggestions: 'Geólogo, Biólogo, Astrónomo, Químico, Licenciado en Ciencias Ambientales, Físico, Matemática, Geógrafo, Médico.'
  },
  {
    id: 'campo2',
    name: "Campo 2",
    icon: icon02,
    carrer: 'Tecnológico',
    questions: [
      '1.	Proyectar edificios y supervisar la construcción de los mismos.',
      '2.	Dirigir la realización material en las obras de construcción de edificios y controlar los materiales empleados en estas.',
      '3.	Diseñar instalaciones relacionadas con las comunicaciones (telefonía, radio, etc.).',
      '4.	Realizar proyectos para la construcción de puentes, carreteras y aeropuertos.',
      '5.	Ser ingeniero de telecomunicación.',
      '6.	Desarrollar proyectos y trabajos de electrónica aplicados a la industria.',
      '7.	Ser arquitecto.',
      '8.	Ser experto en edificación y construcción civil.',
      '9. Realizar tareas relacionadas con el control y la seguridad del tráfico aéreo.',
      '10. Ser ingeniero en electrónica.'
    ],
    results: [
      'Este campo se relaciona con el interés por proyectar y dirigir la construcción de edificios y zonas urbanas, presentando un marcado interés por la realización de proyectos para construir puertos, carreteras y puentes. Las personas con intereses altos en este campo muestran también disposición para diseñar instalaciones para las comunicaciones tales como la radio, telefonía, radar. A la vez, este campo es característico por el interés por desarrollar proyectos aplicados a la industria. En términos generales, interés por diseñar y construir elementos útiles para el hombre. ',
    ],
    suggestionsLabel: 'Las carreras que se encuentran en este campo profesional son:  ',
    suggestions: 'Arquitectura, Ingeniería en Telecomunicación, Aeronáutico, Ingeniería electrónica, Ingeniería civil, Ingeniería Naval e Ingeniería Industrial. '
  },
  {
    id: 'campo3',
    name: "Campo 3",
    icon: icon03,
    carrer: 'Sanitario',
    questions: [
      '1.	Asesorar y tratar a personas con problemas de alimentación.',
      '2.	Diseñar dietas adecuadas para que cada persona pueda mantener una alimentación saludable.',
      '3.	Aplicar conocimientos científicos trabajando en laboratorios donde se preparan medicamentos.',
      '4.	Evaluar y tratar a personas cuyo movimiento está limitado por una lesión, una enfermedad o la vejez.',
      '5.	Ser farmacéutico.',
      '6.	Evaluar, diagnosticar y tratar cualquier problema relacionado con el comportamiento y la salud mental de las personas.',
      '7.	Aplicar los conocimientos de medicina a tratamientos de enfermedades mentales.',
      '8.	Ser odontólogo(a).',
      '9.	Colaborar con el médico en el cuidado de los enfermos.',
      '10. Ser médico.'
    ],
    results: [
      'Este campo se relaciona con el interés por examinar a los enfermos y establecer diagnósticos para diversas enfermedades. Las personas con intereses altos en este campo muestran disposición para realizar intervenciones quirúrgicas, establecer y realizar tratamientos de rehabilitación. A la vez, este campo es característico por el interés por conocer los usos y efectos de los medicamentos, buscar soluciones a los problemas de dentadura, establecer y controlar dietas y planes de alimentación. En términos generales, interés por involucrarse en todo lo relacionado con el cuidado y tratamiento de enfermedades. '
    ],
    suggestionsLabel: 'Las carreras que se encuentran en este campo profesional son: ',
    suggestions: 'Medicina, Odontología, Nutrición, Oftalmología, Psicología, Químico Farmacéutico, Enfermería, Tecnólogo Médico.'
  },
  {
    id: 'campo4',
    name: "Campo 4",
    icon: icon04,
    carrer: 'Científico Social-Humanidades',
    questions: [
      '1.	Analizar e investigar la actuación del hombre en su aspecto social y evolutivo a lo largo del tiempo.',
      '2.	Ejercer la profesión de historiador.',
      '3.	Estudiar la sociedad, los grupos humanos y su desarrollo, aplicando estos conocimientos en análisis sociológicos.',
      '4.	Investigar los fundamentos de la religión católica, analizar textos escritos, restos arqueológicos y tradiciones.',
      '5.	Colaborar con personas o familias para solucionar problemas de tipo social o personal.',
      '6.	Ejercer la profesión de psicólogo(a).',
      '7.	Interpretar y traducir textos aplicando los conocimientos lingüísticos, teóricos y prácticos.',
      '8.	Aplicar  los conocimientos sobre las distintas manifestaciones artísticas.',
      '9.	Ejercer la profesión de especialista en arte.',
      '10.	Ejercer la profesión de sociólogo.'
    ],
    results: [
      'Este campo se relaciona por el interés en el estudio de las acciones del hombre a lo largo del tiempo, el estudio comparativo de los fundamentos e historia de las religiones, así como el desarrollo de conocimientos sobre el tema artístico, esencialmente desarrollan motivación por el estudio del funcionamiento social e interacción de los individuos, todo ello con el fin de ayudar a las personas a resolver problemas sociales. '
    ],
    suggestionsLabel: 'Las carreras que se encuentran en este campo profesional son: ',
    suggestions: 'Antropología, Sociología, Trabajo social, Historia, Arqueología, Filosofía, Teología, Psicología, Ciencias políticas, Educación.'
  },
  {
    id: 'campo5',
    name: "Campo 5",
    icon: icon05,
    carrer: 'Jurídico Social',
    questions: [
      '1.	Ser criminólogo.',
      '2.	Intervenir ante  los tribunales de justicia en nombre de la ley, representando a una persona o al estado.',
      '3.	Ser abogado.',
      '4.	Ser diplomático (embajador).',
      '5.	Representar al propio país en otros países.',
      '6.	Hacer averiguaciones e informar a jueces, policías sobre aspectos relacionados con comportamientos delictivos.',
      '7.	Representar a empresas o trabajadores ante los tribunales de lo social.',
      '8.	Aplicar conocimientos profesionales para resolver problemas relacionados con los trabajadores de una empresa.',
      '9.	Trabajar como diplomado en relaciones laborales.',
      '10.	Trabajar asesorando a las fuerzas de seguridad, en temas referentes a comportamientos criminales.'
    ],
    results: [
      'Este campo se relaciona con el interés por intervenir ante los tribunales de justicia o formar parte de ellos. Las personas con intereses altos en este campo muestran disposición para asesorar en temas jurídicos, realizar investigaciones sobre comportamientos delictivos. Asesorar en conflictos laborales y representar a empresas o trabajadores en tribunales. A la vez, este campo es característico por el interés en el análisis de crímenes o delitos y su relación con el delincuente, así como, el interés por representar al propio país a nivel internacional. En términos generales, interés por las leyes y justicia nacional e internacional. '
    ],
    suggestionsLabel: 'Algunas de las carreras incluidas en este campo son: ',
    suggestions: 'Derecho, especialista en Relaciones Laborales, especialista en relaciones internacionales, Diplomático y Criminólogo.'
  },
  {
    id: 'campo6',
    name: "Campo 6",
    icon: icon06,
    carrer: 'Comunicación - Información',
    questions: [
      '1.	Trabajar como publicista.',
      '2.	Buscar y redactar noticias para publicarlas en la prensa escrita o difundirlas para radio u otros medios.',
      '3.	Trabajar como licenciado en comunicación audiovisual.',
      '4.	Gestionar todo lo relacionado con el sonido de una película, teatro, radio o cualquier medio audiovisual.',
      '5.	Elaborar la información para presentarlas en los medios de comunicación audiovisual.',
      '6.	Diseñar campañas publicitarias para dar a conocer un producto y lograr su aceptación o para difundir mensajes.',
      '7.	Obtener imágenes por medio de fotografía, video y presentarlas en  cualquier soporte.',
      '8.	Ser periodista.',
      '9.	Ser director realizador de medios audiovisuales.',
      '10. Aplicar los conocimientos profesionales para registrar y reproducir contenido en producciones de diverso tipo.'
    ],
    results: [
      'Este campo se relaciona con el interés por buscar, redactar y publicar noticias de interés nacional e internacional. Las personas con intereses altos en este campo muestran disposición para diseñar campañas o elementos de publicidad. A la vez, este campo es característico por el interés hacia el estudio de procesos y técnicas de medios audiovisuales, así como la captación de imágenes y sonidos a través de cámaras fotográficas, videocámaras, grabadoras, etc. En términos generales interés por captar información que deba ser publicada. '
    ],
    suggestionsLabel: 'Algunas de las carreras incluidas en este campo son: ',
    suggestions: 'Ciencias de la comunicación, periodismo, publicidad, comunicación audiovisual (Cine, radio y televisión), Comunicación Social,  Relaciones públicas, Turismo y Ciencias de la Información.',
    technicalCareerLabel: 'Carreras Técnicas: ',
    technicalCareer: 'Técnico en Imagen o en sonido. Publicista gráfico, audiovisual. Locutor de radio y televisión. Comentarista radial y televisivo. Fotografía. Guía turístico, promotores de turismo.'
  },
  {
    id: 'campo7',
    name: "Campo 7",
    icon: icon07,
    carrer: 'Psicopedagógico',
    questions: [
      '1.	Trabajar en la educación de adultos utilizando recursos didácticos.',
      '2.	Enseñar a alumnos con necesidades especiales.',
      '3.	Aplicar pruebas psicológicas, para evaluar la inteligencia, aptitudes y personalidad de los alumnos.',
      '4.	Trabajar como maestro en educación especial.',
      '5.	Trabajar como educador social.',
      '6.	Trabajar como psicólogo escolar.',
      '7.	Trabajar como educador infantil.',
      '8.	Analizar las técnicas y métodos de enseñanza para dirigir y organizar centros educativos.',
      '9.	Impartir las enseñanzas de educación física.',
      '10.	Enseñar a niños de educación primaria.'
    ],
    results: [
      'Este campo se relaciona con el interés por analizar y crear técnicas de enseñanza y procedimientos educativos. Las personas con intereses altos en este campo muestran disposición para evaluar aptitudes de los alumnos, aconsejar sobre métodos para resolver problemas de aprendizaje o de adaptación. A la vez, este campo es característico por el interés hacia la enseñanza de diversas poblaciones como: la educación de adultos, la integración de discapacitados, niños con necesidades especiales, educación física, entre otros. En términos generales, interés por carreras donde exista contacto directo con personas que brinden una orientación profesional en el proceso de enseñanza aprendizaje. '
    ],
    suggestionsLabel: 'Algunas de las carreras incluidas en este campo son: ',
    suggestions: 'Educación Inicial, Primaria, Secundaria, Especial, Física, etc. Psicólogo Escolar.'
  },
  {
    id: 'campo8',
    name: "Campo 8",
    icon: icon08,
    carrer: 'Empresarial - Administrativo - Comercial',
    questions: [
      '1.	Ser administrador de empresas.',
      '2.	Ser economista.',
      '3.	Realizar trámites para empresas.',
      '4.	Trabajar como licenciado en ciencias contables y financieras.',
      '5.	Comprar, vender o alquilar propiedades.',
      '6.	Ser agente inmobiliario (compra venta de casas, departamentos, etc.).',
      '7.	Ejecutar los planes establecidos para la comercialización de productos.',
      '8.	Ser especialista en gestión comercial y marketing.',
      '9.	Aplicar los conocimientos técnicos al campo de los seguros.',
      '10.	Aplicar los conocimientos de economía para resolver problemas prácticos en empresas de diverso tipo.'
    ],
    results: [
      'Este campo se relaciona con el interés por interpretar y resolver problemas prácticos en las empresas. Las personas con intereses altos en este campo muestran disposición para establecer planes de crecimiento en empresas, realizar planes de inversión y comercialización de productos, establecer comunidades de propietarios con reglas y estatutos definidos. A la vez, este campo es característico por el interés hacia la aplicación de conocimientos técnicos en el campo de los seguros, asimismo interés por la realización de trámites administrativos y por actuar como mediador de venta o compras de viviendas o terrenos. En términos generales, interés por carreras donde sea necesario la realización de trabajos de oficina y gestión empresarial. '
    ],
    suggestionsLabel: 'Algunas de las carreras incluidas en este campo son: ',
    suggestions: 'Economía, Administración, Negocios Internacionales y Globales, Gestión Empresarial, Contabilidad y Finanzas, Banca y Seguros, Marketing y Gestión Comercial.'
  },
  {
    id: 'campo9',
    name: "Campo 9",
    icon: icon09,
    carrer: 'Informática',
    questions: [
      '1.	Ser ingeniero en informática.',
      '2.	Aplicar los conocimientos informáticos sobre sistemas operativos y tecnología de la información.',
      '3.	Trabajar como administrador de sistemas informáticos.',
      '4.	Realizar trabajos de informática aplicada a las comunicaciones.',
      '5.	Ser especialista en telemática.',
      '6.	Trabajar en el desarrollo de aplicaciones informáticas.',
      '7.	Participar en el diseño de aplicaciones informáticas realizando la aplicación de las mismas.',
      '8.	Establecer y manejar los sistemas informáticos.',
      '9.	Trabajar como especialista en multimedia.',
      '10.	Aplicar conocimientos de informática, electrónica y telecomunicaciones para el tratamiento gráfico de datos. '
    ],
    results: [
      'Este campo se relaciona por el interés por actividades como: análisis y arquitectura de computadoras, programación de aplicaciones informáticas, crear y manejar sistemas informáticos para la administración y gestión de empresas, informática aplicada a las comunicaciones y tratamiento de datos aplicando la informática, electrónica y telecomunicaciones. '
    ],
    suggestionsLabel: 'Algunas de las profesiones en esta categoría son: ',
    suggestions: 'Ingeniería informática, desarrollador informático, administrador de sistemas informáticos, especialista en telecomunicaciones.'
  },
  {
    id: 'campo10',
    name: "Campo 10",
    icon: icon10,
    carrer: 'Agrario - Agropecuario - Ambiental',
    questions: [
      '1.	Ser especialista en trabajos forestales y conservación del medio ambiente.',
      '2.	Organizar explotaciones agrícolas o agropecuarias.',
      '3.	Trabajar en explotaciones ganaderas.',
      '4.	Ser veterinario.',
      '5.	Potenciar los espacios naturales para la conservación de bosques.',
      '6.	Dirigir fincas para aumentar la productividad de granjas.',
      '7.	Ser ingeniero agrícola.',
      '8.	Realizar tareas de vigilancia y protección de medio ambiente en bosques, jardines o parques.',
      '9.	Trabajar como ingeniero agrónomo.',
      '10.	Prevenir, diagnosticar y tratar las enfermedades que padezcan los animales.'
    ],
    results: [
      'Este campo se relaciona por el interés por actividades como: análisis y arquitectura de computadoras, programación de aplicaciones informáticas, crear y manejar sistemas informáticos para la administración y gestión de empresas, informática aplicada a las comunicaciones y tratamiento de datos aplicando la informática, electrónica y telecomunicaciones. '
    ],
    suggestionsLabel: 'Algunas de las profesiones incluidas en este campo son: ',
    suggestions: 'Ingeniero Agrónomo, Veterinario, Ingeniero Agrícola, Ingeniero en Trabajo Forestales y Conservación del Medio Ambiente, Geógrafo.'
  },
  {
    id: 'campo11',
    name: "Campo 11",
    icon: icon11,
    carrer: 'Artístico Plástico - Artesanía - Moda',
    questions: [
      '1.	Ser dibujante, ilustrador de publicaciones.',
      '2.	Trabajar como diseñador(a) de interiores.',
      '3.	Trabajar como especialista en artes plásticas y diseño.',
      '4.	Diseñar y elaborar productos relacionados con la moda.',
      '5.	Ser diseñador(a) especialista en moda.',
      '6.	Crear piezas de cerámica e investigar técnicas con respecto a este trabajo.',
      '7.	Crear diseños para la mejora de espacios interiores.',
      '8.	Dibujar o diseñar elementos para la decoración.',
      '9.	Conservar y restaurar bienes culturales.',
      '10.	Emplear técnicas de ilustración gráfica para realizar ilustraciones en libros.'
    ],
    results: [
      'Este campo se relaciona con el interés por ejecutar tareas de conservación y restauración de bienes culturales, tales como: esculturas, cuadros, elementos arqueológicos, entre otros. Así como la ilustración de libros empleando diversas técnicas de expresión gráfica. Las personas con intereses altos en este campo muestran disposición para crear diseños, para mejorar espacios interiores, dibujar o diseñar en computadora elementos para la decoración textil, cerámica o mobiliario. A la vez, este campo es característico por el interés de las personas por el diseño y elaboración de elementos relacionados con la moda y a la creación de piezas de cerámica. En términos generales, interés por actividades referidas al campo artístico plástico.'
    ],
    suggestionsLabel: 'Algunas de las profesiones incluidas en este campo son: ',
    suggestions: 'Dibujante, Restaurador de Bienes Culturales, Ilustrador de Publicaciones, Diseñador Gráfico, Diseñador de Interiores y de Modas, Arquitecto, artes culinarias.'
  },
  {
    id: 'campo12',
    name: "Campo 12",
    icon: icon12,
    carrer: 'Artístico Musical - Espectáculo',
    questions: [
      '1.	Formar parte de una orquesta o actuar como solista.',
      '2.	Dar recitales o conciertos, interpretando canciones clásicas o modernas.',
      '3.	Ser músico o instrumentista.',
      '4.	Ser cantante.',
      '5.	Bailar en representaciones públicas.',
      '6.	Ser bailarín(a).',
      '7.	Investigar y analizar la historia de la música.',
      '8.	Ser actor o actriz profesional.',
      '9.	Actuar en representaciones teatrales interpretando a un personaje.',
      '10.	Doblar las voces de actores extranjeros para películas de cine o televisión.'
    ],
    results: [
      'Este campo se relaciona con el interés por realizar representaciones de personajes en el teatro, doblar voces de actores extranjeros para versiones traducidas de películas, dar recitales y conciertos como solista o formar parte de un grupo. Las personas con intereses altos en este campo muestran disposición por bailar en representaciones públicas interpretando danza clásica o moderna. A la vez, este campo es característico por el interés en tocar instrumentos musicales e investigar sobre la música. En términos generales, interés por actividades referidas al campo artístico musical.'
    ],
    suggestionsLabel: 'Algunas de las profesiones incluidas en este campo son: ',
    suggestions: 'Cantante, Actor Profesional, Actor de Doblaje, Músico Instrumentista, Bailarín, Musicólogo.'
  },
  {
    id: 'campo13',
    name: "Campo 13",
    icon: icon13,
    carrer: 'Fuerzas Armadas - Seguridad - Protección',
    questions: [
      '1.	Apagar incendios o prevenirlos.',
      '2.	Rescatar a personas que se encuentren en situaciones problemáticas en el mar.',
      '3.	Realizar las tareas necesarias para garantizar la seguridad de los ciudadanos.',
      '4.	Realizar trabajos técnicos, científicos o de mandos relacionados con los fines del ejército.',
      '5.	Ser bombero.',
      '6.	Ser oficial de carrera del ejército.',
      '7.	Trabajar como técnico en salvamento acuático.',
      '8.	Ser policía.',
      '9.	Custodiar establecimientos u oficinas para evitar robos u otros hechos delictivos.',
      '10.	Ser especialista del ejército.'
    ],
    results: [
      'Este campo se relaciona con el interés por realizar trabajos técnicos o de mando, formando parte del ejército o algún rango de nivel militar. Las personas con intereses altos en este campo muestran disposición por trabajar para garantizar la seguridad de la ciudadanía y el ejercicio de las libertades, rescatar y practicar los primeros auxilios a personas en situación de riesgo en lugares inhóspitos. A la vez, este campo es característico por el interés hacia el trabajo en la mecánica, electricidad, electrónica, formando parte de algún estamento militar. En términos generales, interés por actividades que se encuentren relacionadas con el campo militar en todas sus variantes.'
    ],
    suggestionsLabel: 'Algunas de las carreras relacionadas en este campo son: ',
    suggestions: 'Oficial del ejército, de la FAP, la Marina, de la Policía, CITEN, etc.'
  },
  {
    id: 'campo14',
    name: "Campo 14",
    icon: icon14,
    carrer: 'Deportivo',
    questions: [
      '1.	Trabajar como conductor de actividades físicas y deportivas.',
      '2.	Organizar actividades físicas o deportivas.',
      '3.	Trabajar como maestro especialista en educación física.',
      '4.	Ser director técnico deportivo.',
      '5.	Entrenar a deportistas o equipos para perfeccionar sus conocimientos y aptitudes.',
      '6.	Asesorar sobre aspectos de la actividad física y el deporte en centros escolares.',
      '7.	Enseñar a realizar un deporte determinado.',
      '8.	Ser entrenador deportivo.',
      '9.	Utilizar los conocimientos sobre masaje y anatomía humana para ayudar a los deportistas a recuperarse de lesiones.',
      '10.	Trabajar como masajista deportivo.'
    ],
    results: [
      'Este campo se relaciona con el interés por planificar, desarrollar, dirigir y evaluar procesos y programas de actividad física y deportiva en centros escolares y deportivos. A la vez este campo es característico por el interés en brindar ayuda a los deportistas para la recuperación de sus lesiones, mediante masajes y técnicas fisioterapéuticas. En términos generales interés por actividades físicas y el entrenamiento a deportistas. '
    ],
    suggestionsLabel: 'Algunas de las profesiones relacionadas en este campo son: ',
    suggestions: 'Licenciado en Ciencias del deporte, Técnico Deportivo, Entrenador.'
  },
  {
    id: 'campo15',
    name: "Campo 15",
    icon: icon15,
    carrer: 'Turismo y Hotelería',
    questions: [
      '1.	Trabajar como especialista en información turística.',
      '2.	Administrar servicios turísticos.',
      '3.	Trabajar como técnico superior en alojamiento.',
      '4.	Gestionar destinos turísticos en una agencia de viajes.',
      '5.	Dirigir o administrar restaurantes; crear productos gastronómicos para ofrecerles a los clientes.',
      '6.	Trabajar como tripulante de cabina de pasajeros o auxiliar de barco.',
      '7.	Organizar todo lo necesario para recibir a los clientes de un hotel.',
      '8.	Procurar la mejora del turismo incluyendo actividades culturales.',
      '9.	Atender a los pasajeros en vuelo de líneas aéreas, barcos o cruceros.',
      '10.	Idear, preparar y dirigir programas de animación cultural.'
    ],
    results: [
      'Este campo se relaciona con el interés por desempeñarse en la administración de servicios turísticos y hoteleros, diseño de programas, paquetes y planes turísticos, gestión y promoción del desarrollo turístico y hotelero. También se aprecia preferencia por la dirección de restaurantes o preparación de alimentos.'
    ],
    suggestionsLabel: 'Las carreras incluidas en esta área son: ',
    suggestions: 'técnico o licenciado en turismo y hotelería, tripulante de cabina o de barco, gastronomía, gestión y/o administración de restaurantes, artes culinarias, etc.'
  },
]

export default TEST