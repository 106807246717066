import IButton from './props'
import { ComponentButton } from './styles'

const Button = ({
  onClick,
  loading,
  outline,
  type,
  upperCase,
  round,
  value,
  style,
  disabled,
  bgColor
}: IButton) => {
  const returnHandleClick = !loading ? onClick : undefined
  const returnValue = loading ? 'Cargando...' : value

  return (
    <ComponentButton
      bgColor={bgColor}
      outline={outline}
      onClick={returnHandleClick}
      type={type}
      upperCase={upperCase}
      round={round}
      style={style}
      disabled={disabled}
    >
      {returnValue}
    </ComponentButton>
  )
}

Button.defaultProps = {
  loading: false,
  outline: false,
  type: 'button',
  upperCase: false,
  nameIcon: '',
  round: false,
  disabled: false
}

export default Button
