import { ISelect } from './props'
import { ComponentSelect, Container, Label } from './styles'

const Select = ({
  children,
  label,
  value,
  onChange,
}: ISelect) => {
  return (
    <Container>
      <ComponentSelect
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {children}
      </ComponentSelect>

      {label && (
        <Label>{label}</Label>
      )}
    </Container>
  )
}

export default Select