import { Toast } from "components"
import { IStudent } from "interfaces"
import { createSchool, deleteSchool, getSchools, getStudentPaging } from "./api"

export const schoolsList = async (
  setSchools: Function
) => {
  try{
    const response = await getSchools()

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    setSchools(response.schools)
  }catch(error: any){
    if (error.message === 'Token inválido') {
      // setView(1)
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      setSchools([])
      Toast({
        type: 'error',
        message: 'No se pudo obtener la lista, intente nuevamente'
      })
    }
  }
}

export const createNewSchool = async (
  name: string,
  callback: Function,
) => {
  try{
    if(!name){
      return Toast({
        type: 'error',
        message: 'Debe ingresar un nombre'
      })
    }

    const response = await createSchool(name)

    if (response?.response !== 'success') {
      throw new Error(response?.response)
    }

    callback()
    Toast({
      type: 'success',
      message: 'Escuela creada correctamente'
    })
  }catch(error: any){
    if (error.message === 'Token inválido') {
      // setView(1)
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: 'No se pudo crear la escuela, intente nuevamente'
      })
    }
  }
}

export const removeSchool = async (
  id: number,
  callback: Function,
) => {
  try{
    const response = await deleteSchool(id)

    if (response?.response !== 'success') {
      throw new Error(response?.response)
    }

    callback()
    Toast({
      type: 'success',
      message: 'Escuela eliminada correctamente'
    })
  }catch(error: any){
    if (error.message === 'Token inválido') {
      // setView(1)
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: 'No se pudo eliminar la escuela, intente nuevamente'
      })
    }
  }
}

export const studentsListPaging = async (
  setLoading: Function,
  setStudents: React.Dispatch<React.SetStateAction<IStudent[]>>,
  page: number,
  setTotalPages: Function,
  setTotal: Function,
  filters: { search: string, startDate: string, endDate: string, origin: string, school?: string }
) => {
  try {
    setLoading(true)
    const response = await getStudentPaging(
      page,
      filters,
    )

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    setStudents(response.students)
    setTotal(response.total)
    setTotalPages(Math.ceil(response.total / 10))

  } catch (error: any) {
    if (error.message === 'Token inválido') {
      // setView(1)
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: 'No se pudo obtener la lista, intente nuevamente'
      })
    }
  } finally {
    setLoading(false)
  }
}
