import { Toast } from "components"

export const handleCopyClipboard = (text: string) => {
  navigator.clipboard.writeText(`${text}`)
  Toast({
    type: 'success',
    message: 'Copiado'
  })
}

export const lowerCase = (text: string | undefined) => {
  if (!text) return ''
  return text.toLowerCase()
}

export const upperCase = (text: string | undefined) => {
  if (!text) return ''
  return text.toUpperCase()
}