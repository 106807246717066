import { Button, Input, Modal, Toast } from 'components'
import { useEffect, useState } from 'react'
import { listConfig, updateConfig } from './services'

const ModalConfig = ({
  open,
  onClose,
}:{
  open: boolean,
  onClose: () => void, 
}) => {
  const [loading, setLoading] = useState(false)
  const [config, setConfig] = useState<any>({})

  // only update amount test self-pay
  useEffect(() => {
    if(!open) return
    listConfig(setLoading, setConfig)
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Configuración"
    >
      <>
        {loading
          ? <>Cargando...</>
          : (
            <>
              <Input
                type='number'
                value={config?.[0]?.value || 0}
                onChange={(value) => setConfig([{ ...config[0], value }])}
                label='Monto de test (S/.) automático'
              />

              <Button
                style={{ marginTop: '1rem' }}
                value='Actualizar'
                onClick={() => {
                  if(config?.[0]?.value < 10) {
                    Toast({
                      type: 'error',
                      message: 'El monto mínimo es de S/. 10.00'
                    })
                    return
                  }
                
                  const payload = {
                    id: config?.[0]?.id,
                    value: config?.[0]?.value,
                    type: config?.[0]?.type,
                  }

                  updateConfig(
                    setLoading,
                    payload,
                    onClose,
                  )
                }}
              />
            </>
          )}
      </>
    </Modal>
  )
}

export default ModalConfig;