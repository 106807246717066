import { useAuth } from "auth/AuthContext";
import { TypePays, TypeProcesses } from "data/types";
import { useHistory } from "react-router-dom";
import { ButtonExit, Container, ItemProcess, Table, TableContainer, TdProcess, TrProcess } from "./styles";

const Dashboard = () => {
  const history = useHistory();
  const { logout } = useAuth();

  const onProcess = (id: string) => {
    history.push(`/process/${id}`)
  }

  const onPay = (id: string) => {
    history.push(`/pay/${id}`)
  }

  return (
    <Container>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Test</th>
            </tr>
          </thead>
          <tbody>
            {
              TypeProcesses?.map(({ id, number, name }) => {
                return (
                  <TrProcess key={id} onClick={() => onProcess(id)}>
                    <td width={10}>{number}</td>
                    <TdProcess>
                      <ItemProcess>
                        <span>{name}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
                          <path d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z" />
                        </svg>
                      </ItemProcess>
                    </TdProcess>
                  </TrProcess>
                )
              })
            }
          </tbody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Pago</th>
            </tr>
          </thead>
          <tbody>
            {
              TypePays?.map(({ id, number, name }) => {
                return (
                  <TrProcess key={id} onClick={() => onPay(id)}>
                    <td width={10}>{number}</td>
                    <TdProcess>
                      <ItemProcess>
                        <span>{name}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
                          <path d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z" />
                        </svg>
                      </ItemProcess>
                    </TdProcess>
                  </TrProcess>
                )
              })
            }
          </tbody>
        </Table>
      </TableContainer>
      <ButtonExit
        onClick={() => logout()}
      >
        Cerrar sesión
      </ButtonExit>
    </Container>
  )
}

export default Dashboard