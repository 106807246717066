import * as colors from 'constants/colors'
import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  gap: 20px;
`

export const TableContainer = styled.section`
  overflow: auto;
  min-width: 200px;
`

export const Table = styled.table`
  width: 100%;
  border: 1px solid #bfbfbf;
  border-collapse: collapse;
  border-radius: 10px;
  th, td{
    padding: 10px;
    border-bottom: 1px solid #bfbfbf;
    border-collapse: collapse;
    vertical-align: middle;
  }
`

export const TrProcess = styled.tr`
  cursor: pointer;
  &:hover{
    background-color: #ffe9e0;
  }
`

export const TdProcess = styled.td`
  padding: 0 !important;
`

export const ItemProcess = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  fill: #bfbfbf;
  span{
    color: ${colors.primary01};
    font-weight: 600;
  }
`

export const ButtonExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: fit-content;
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  color: #fff;
  background: red;
  cursor: pointer;
`