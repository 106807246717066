import { Modal } from "components"
import { TStatusMP } from "constants/types"
import { IPay } from "interfaces"
import { useEffect, useState } from "react"
import { handlePayment } from "./services"
import { Body, Container, ItemTab, TabContainer, stylesModal } from "./styled"

const Details = ({
  onClose,
  open,
  pay
}: {
  onClose: () => void
  open: boolean,
  pay: IPay
}) => {
  const [option, setOption] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()

  const returnStep = () => {
    switch (option) {
      case 1:
        return <ReturnStepOne />
      case 2:
        return <ReturnStepTwo />
      default:
        break
    }
  }

  const returnStatus = (status: TStatusMP) => {
    switch (status) {
      case 'approved':
        return 'Aprobado ✅'

      case 'pending':
      case 'in_process':
      case 'in_mediation':
        return 'Pendiente'

      case 'refunded':
        return 'Reembolsado 💸'

      case 'rejected':
      case 'cancelled':
      case 'charged_back':
      case 'authorized':
        return 'Rechazado ❌'
    }
  }

  const ReturnStepOne = () => (
    <>
      <p><strong>Tipo de tarjeta de pago: </strong>{data?.body?.payment_type_id}</p>
      <p><strong>Tarjeta de pago: </strong>********** {data?.body?.card?.last_four_digits || '----'}</p>
      <p><strong>Nombre de tarjeta: </strong>({data?.body?.card?.cardholder?.identification?.type} {data?.body?.card?.cardholder?.identification?.number}) {data?.body?.card?.cardholder?.name}</p>

      <p><strong>Estado: </strong> {returnStatus(data?.body?.status)}</p>
      <p><strong>Monto recibido: </strong> {data?.body?.transaction_details?.net_received_amount?.toFixed(2)}</p>
      <p><strong>Monto pagado: </strong> {data?.body?.transaction_details?.total_paid_amount?.toFixed(2)}</p>
    </>
  )

  const ReturnStepTwo = () => (
    <>
      <textarea
        style={{ width: '100%', height: '100%' }}
        value={JSON.stringify(data, null, 2)}
        readOnly></textarea>
    </>
  )

  const validatePayment = async () => {
    if (!pay.id) return;

    const response = await handlePayment(setLoading, pay.id)
    setData(response.payment)
  }

  useEffect(() => {
    validatePayment()
  }, [pay])

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={stylesModal}
      title={`Detalle de pago ${pay.id}`}
      closeInClickOut>
      {loading && <p>Cargando...</p>}
      {!loading && (
        <Container>
          <TabContainer>
            <ItemTab
              isActive={option === 1}
              onClick={() => setOption(1)}>
              Pago
            </ItemTab>
            <ItemTab
              isActive={option === 2}
              onClick={() => setOption(2)}>
              JSON
            </ItemTab>
          </TabContainer>

          <Body>
            {returnStep()}
          </Body>
        </Container>
      )}
    </Modal>
  )
}

export default Details