import { Toast } from "components"
import { getPayment } from "./api"

export const handlePayment = async (
  setLoading: Function,
  id: number
) => {
  try {
    setLoading(true)
    const response = await getPayment(id)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    return response
  } catch (error: any) {
    if (error.message === 'Token inválido') {
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: error.message
      })
    }
  } finally {
    setLoading(false)
  }
}
