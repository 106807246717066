import { Button, Input, Modal, Select, Toast } from "components"
import * as links from 'constants/links'
import { TTypeProcesses } from "constants/types"
import { TypeProcesses } from "data/types"
import { saveAs } from 'file-saver'
import useDebounce from "hooks/useDebounce"
import { IStudent } from "interfaces"
import { jsPDF } from "jspdf"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import * as XLSX from "xlsx"
import { getStudents } from "./api"
import { bodyPDF } from "./pdf"
import { createNewSchool, removeSchool, schoolsList, studentsListPaging } from "./services"
import { ButtonBack, ButtonExcel, ButtonPdf, ButtonSchools, Container, Header, Info, PaginationContainer, SearchContainer, Table, TableContainer, Title } from "./styled"

const Process = () => {
  const { id } = useParams<{
    id: TTypeProcesses
  }>();
  const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [students, setStudents] = useState<IStudent[]>([])
  const [openSchools, setOpenSchools] = useState(false)
  const [schools, setSchools] = useState<Array<{ id: number, name: string }>>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [filters, setFilters] = useState({
    search: '',
    startDate: '',
    endDate: '',
    school: '',
    origin: id,
  })
  const searchDebounce = useDebounce(filters.search, 1000)
  const process: string = TypeProcesses.find((process) => process.id === id)?.name || ''

  const getSchools = async () => {
    schoolsList(setSchools)
  }

  useEffect(() => {
    if(id === 'utp') getSchools()
  }, [])

  useEffect(() => {
    studentsListPaging(
      setLoading,
      setStudents,
      page,
      setTotalPages,
      setTotal,
      filters
    )
  }, [page, searchDebounce, filters.startDate, filters.endDate, filters.school])

  const returnYears = (date: string) => {
    const today = new Date()
    const birthDate = new Date(date)
    let years = today.getFullYear() - birthDate.getFullYear()
    const months = today.getMonth() - birthDate.getMonth()
    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      years--
    }
    return years
  }

  const exportPDF = (student: IStudent) => {
    const doc = new jsPDF({ putOnlyUsedFonts: true, unit: 'mm', format: 'a4' });
    bodyPDF(doc, student, id)
    doc.save(`result-${student.dni}.pdf`);
  }

  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  const exportExcel = async () => {
    try {
      if (loadingExcel || students.length === 0) return
      setLoadingExcel(true)
      const response = await getStudents(filters)

      if (response.response !== 'success') throw new Error('Error al obtener los datos')

      const data = [
        [
          'DNI',
          'EDAD',
          'GENERO',
          'NOMBRES',
          'APELLIDOS',
          'EMAIL',
          'CELULAR',
          'FECHA',
          ...((id === 'utp') ? ['COLEGIO'] : [])
        ]
      ]

      response.students.forEach((student: any) => {
        data.push([
          student.dni,
          returnYears(student.dateOfBirth),
          student.gender === 'M' ? 'Masculino' : 'Femenino',
          student.names,
          student.lastNames,
          student.email,
          student.phone,
          new Date(student.updatedAt).toLocaleString(),
          ...((id === 'utp') ? [student.school] : [])
        ])
      })

      // Crear un nuevo libro de Excel
      const wb = XLSX.utils.book_new();

      // Agregar una nueva hoja al libro de Excel
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

      // Guardar el libro de Excel como archivo
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      saveAs(blob, `estudiantes-${new Date().getTime()}.xlsx`);
    } catch (error: any) {
      Toast({
        type: 'error',
        message: error.message === 'Load failed' ||
          error.message === 'NetworkError when attempting to fetch resource.'
          ? 'Intente nuevamente'
          : error.message
      })
    } finally {
      setLoadingExcel(false)
    }
  }

  return (
    <Container>
      <Header>
        <ButtonBack onClick={() => history.push(links.dashboard)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="220" viewBox="0 0 1024 1024">
            <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z" />
          </svg>
        </ButtonBack>
        <Title>Lista de estudiantes de {process}</Title>
      </Header>
      <SearchContainer isUtp={id === 'utp'}>
        <Input
          type="search"
          placeholder="Buscar por nombres, apellidos, DNI, correo o celular"
          label="Buscar"
          value={filters.search}
          onChange={(search) => setFilters({ ...filters, search })}
        />

        <Input
          type="date"
          label="Desde"
          maxDate={filters.endDate}
          value={filters.startDate}
          onChange={(startDate) => setFilters({ ...filters, startDate })}
        />

        <Input
          type="date"
          label="Hasta"
          minDate={filters.startDate}
          value={filters.endDate}
          onChange={(endDate) => setFilters({ ...filters, endDate })}
        />

        {id === 'utp' && (
          <>
            <Select
              label='Colegio'
              value={filters.school}
              onChange={(school) => setFilters({ ...filters, school })}
            >
              <option value=''>Todos</option>
              {
                schools.map((school) => (
                  <option key={school.id} value={school.name}>{school.name}</option>
                ))
              }
            </Select>

            <ButtonSchools
              onClick={() => setOpenSchools(!openSchools)}
            >
              Colegios
            </ButtonSchools>
          </>
        )}

        <ButtonExcel
          disabled={loadingExcel || students.length === 0}
          onClick={exportExcel}
        >
          <svg id="excel" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 512 512">
            <path d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z" />
          </svg>
        </ButtonExcel>
      </SearchContainer>

      <Info>
        <strong>Recuerda: </strong> Si tienes las fechas seleccionadas, el excel se exportará con los estudiantes registrados en ese rango de fechas.
      </Info>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>DNI</th>
              <th>Estudiante</th>
              <th>Edad</th>
              <th>Género</th>
              {id === 'utp' && <th>Colegio</th>}
              <th>E-mail</th>
              <th>Teléfono</th>
              <th>Fecha</th>
              <th>Exportar</th>
            </tr>
          </thead>
          <tbody>
            {
              loading ? (
                <tr>
                  <td colSpan={9}>cargando...</td>
                </tr>
              ) : (
                <>
                  {
                    students.length === 0 && (
                      <tr>
                        <td colSpan={9}>No hay estudiantes registrados</td>
                      </tr>
                    )
                  }
                  {students.length > 0 && students.map((student) => (
                    <tr key={student.id}>
                      <td>{student.dni}</td>
                      <td>{student.names} {student.lastNames}</td>
                      <td>{returnYears(student.dateOfBirth)}</td>
                      <td>{student.gender}</td>
                      {id === 'utp' && <td>{student.school}</td>}
                      <td>{student.email}</td>
                      <td>{student.phone}</td>
                      <td>
                        {student.updatedAt
                          ? new Date(student.updatedAt).toLocaleString()
                          : ''
                        }
                      </td>
                      <td>
                        <ButtonPdf
                          onClick={() => exportPDF(student)}>
                          <svg id="pdf" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 256 256">
                            <path d="M224 152a8 8 0 0 1-8 8h-24v16h16a8 8 0 0 1 0 16h-16v16a8 8 0 0 1-16 0v-56a8 8 0 0 1 8-8h32a8 8 0 0 1 8 8ZM92 172a28 28 0 0 1-28 28h-8v8a8 8 0 0 1-16 0v-56a8 8 0 0 1 8-8h16a28 28 0 0 1 28 28Zm-16 0a12 12 0 0 0-12-12h-8v24h8a12 12 0 0 0 12-12Zm88 8a36 36 0 0 1-36 36h-16a8 8 0 0 1-8-8v-56a8 8 0 0 1 8-8h16a36 36 0 0 1 36 36Zm-16 0a20 20 0 0 0-20-20h-8v40h8a20 20 0 0 0 20-20ZM40 112V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88v24a8 8 0 0 1-16 0V96h-48a8 8 0 0 1-8-8V40H56v72a8 8 0 0 1-16 0Zm120-32h28.69L160 51.31Z" />
                          </svg>
                        </ButtonPdf>
                      </td>
                    </tr>
                  ))}
                </>
              )
            }
          </tbody>
        </Table>
      </TableContainer>
      {
        !loading && students.length > 0 && (
          <PaginationContainer>
            <p style={{ marginRight: 'auto' }}>
              Total: {total}
            </p>

            <Button
              onClick={() => page > 1 && setPage(page - 1)}
              value="Anterior"
            />
            <span>{page} / {totalPages}</span>
            <Button
              onClick={() => page < totalPages && setPage(page + 1)}
              value="Siguiente"
            />
          </PaginationContainer>
        )
      }

      <Modal
        open={openSchools}
        onClose={() => setOpenSchools(!openSchools)}
        title='Colegios'
      >
        {schools.map((school) => (
          <p
            key={school.id}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              borderTop: '1px solid #ccc',
            }}
          >
            {school.name}
            <button
              onClick={() => {
                const callback = () => {
                  setOpenSchools(false)
                  getSchools()
                }
                removeSchool(school.id, callback)
              }}
              style={{
                background: 'red',
                padding: '0.5rem',
                color: '#fff',
                borderRadius: '0.5rem',
                cursor: 'pointer',
              }}
            >
              X
            </button>
          </p>
        ))}

        <form
          style={{
            borderTop: '1px solid #ccc',
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '1rem',
            padding: '1rem 0',
          }}
          onSubmit={(e) => {
            e.preventDefault()
            const name = e.currentTarget.school.value

            const callback = () => {
              setOpenSchools(false)
              getSchools()
            }

            createNewSchool(name, callback)
          }}
        >
          <input
            style={{ border: '1px solid #ccc', padding: '0.5rem' }}
            type="text"
            name='school'
          />
          <button
            type='submit'
            style={{
              background: 'green',
              padding: '0.5rem',
              color: '#fff',
              borderRadius: '0.5rem',
              cursor: 'pointer',
            }}
          >
            Agregar
          </button>
        </form>
      </Modal>
    </Container>
  )
}

export default Process