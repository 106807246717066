import { useAuth } from 'auth/AuthContext'
import * as links from 'constants/links'
import { Route as ComponentRoute, Redirect } from 'react-router-dom'
import { IRoute } from './props'

const Route = ({
  component: Component,
  isAuth,
  isPrivate,
  ...props
}: IRoute) => {
  const { loggedIn } = useAuth()

  const validateAuth = () => {
    if (!loggedIn) return <Component {...props} />
    return <Redirect to={links.dashboard} />
  }

  const validatePrivate = () => {
    if (loggedIn) return <Component {...props} />
    return <Redirect to={links.login} />
  }

  const validateRoute = () => {
    if (isAuth) return validateAuth()
    if (isPrivate) return validatePrivate()
    return <Component {...props} />
  }

  return <ComponentRoute {...props} render={validateRoute} />
}

Route.defaultProps = {
  exact: true,
}

export default Route
