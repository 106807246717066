import { LEGENDS } from "data/information"
import { Container, ItemList, List, Title } from "./styled"

const Legend = ({
  title
}: {
  title: string
}) => {
  return (
    <Container>
      <Title>{title}</Title>

      <List>
        {LEGENDS.map(({ id, value, description }) => (
          <ItemList key={id}>
            <strong>/{value}/</strong>
            <span>Significa</span>
            <p>{description}</p>
          </ItemList>
        ))}
      </List>
    </Container>
  )
}

export default Legend