export const TITLE: string = 'Test Vocacional';

export const LEGENDS: {
  id: number;
  value: string;
  description?: string;
}[] = [
    {
      id: 0,
      value: '0',
      description: 'No Conozco'
    },
    {
      id: 1,
      value: '1',
      description: 'Me Desagrada'
    },
    {
      id: 2,
      value: '2',
      description: 'Me es Indiferente(ni me agrada, ni me desagrada)'
    },
    {
      id: 3,
      value: '3',
      description: 'Me Agrada'
    }
  ]

export const LOGIN_TITLE: string = 'Iniciar Sesión';

export const LOGIN_BUTTON: string = 'Ingresar';

export const LOADING_BUTTON: string = 'Cargando...';

export const VALIDATION_BUTTON: string = 'Validar'

export const LINK: string = 'https://consultoriomga.com/test-vocacional/';