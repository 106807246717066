import { Toast } from 'components'
import { getPaymentsPaging } from './api'

export const paymentsListPaging = async (
  setLoading: Function,
  setPays: Function,
  page: number,
  setTotalPages: Function,
  setTotal: Function,
  filters: { search: string, startDate: string, endDate: string }
) => {
  try{
    setLoading(true)

    const response = await getPaymentsPaging(
      page,
      filters,
    )

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    setPays(response.payments)
    setTotal(response.total)
    setTotalPages(Math.ceil(response.total / 10))
  } catch (error: any) {
    if (error.message === 'Token inválido') {
      localStorage.removeItem('token')
      Toast({
        type: 'error',
        message: 'Su sesión ha expirado'
      })
    } else {
      Toast({
        type: 'error',
        message: 'No se pudo obtener la lista, intente nuevamente'
      })
    }
  } finally {
    setLoading(false)
  }
}