import { useState } from 'react'
import IInput from './props'
import {
  ComponentInput,
  Container,
  Error,
  Label,
  styleIconPassword
} from './styles'

const Input = ({
  error,
  onChange,
  onBlur,
  name,
  label,
  placeholder,
  type,
  value,
  maxDate,
  minDate
}: IInput) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)
  const returnIcon = showPassword ? 'show-eye' : 'hide-eye'
  const returnPlaceholder = (type?.includes('password') && !showPassword) ? 'xxxxxxxx' : placeholder
  const returnType = showPassword ? 'text' : type

  return (
    <Container>
      <ComponentInput
        error={error}
        id={name}
        name={name}
        step={type === 'number' ? '0.01' : undefined}
        onChange={(e: any) => onChange?.(e.target.value)}
        onBlur={(e: any) => onBlur?.(e.target.value)}
        placeholder={returnPlaceholder}
        type={returnType}
        value={value}
        min={type === 'number' ? 0 :
          minDate && new Date(minDate).toISOString().split('T')[0]}
        max={maxDate && new Date(maxDate).toISOString().split('T')[0]}
      />

      {label && (
        <Label>{label}</Label>
      )}

      {type?.includes('password') && (
        // <Icon
        //   name={returnIcon}
        //   onClick={toggleShowPassword}
        //   pointer
        //   style={styleIconPassword}
        // />

        <svg id='eye' viewBox='0 0 20 20' width={24} height={24} xmlns='http://www.w3.org/2000/svg' onClick={toggleShowPassword} style={styleIconPassword}>
          {
            showPassword ? (
              <path d='M7.73437 10C7.73437 10.5802 7.96483 11.1366 8.37507 11.5468C8.78531 11.957 9.34171 12.1875 9.92187 12.1875C10.502 12.1875 11.0584 11.957 11.4687 11.5468C11.8789 11.1366 12.1094 10.5802 12.1094 10C12.1094 9.41984 11.8789 8.86344 11.4687 8.4532C11.0584 8.04297 10.502 7.8125 9.92187 7.8125C9.34171 7.8125 8.78531 8.04297 8.37507 8.4532C7.96483 8.86344 7.73437 9.41984 7.73437 10ZM18.4023 9.49609C16.5508 5.5957 13.7519 3.63281 9.99999 3.63281C6.24608 3.63281 3.44921 5.5957 1.59765 9.49805C1.52338 9.65531 1.48486 9.82706 1.48486 10.001C1.48486 10.1749 1.52338 10.3466 1.59765 10.5039C3.44921 14.4043 6.24804 16.3672 9.99999 16.3672C13.7539 16.3672 16.5508 14.4043 18.4023 10.502C18.5527 10.1855 18.5527 9.81836 18.4023 9.49609ZM9.92187 13.4375C8.02343 13.4375 6.48437 11.8984 6.48437 10C6.48437 8.10156 8.02343 6.5625 9.92187 6.5625C11.8203 6.5625 13.3594 8.10156 13.3594 10C13.3594 11.8984 11.8203 13.4375 9.92187 13.4375Z' />
            ) : (
              <>
                <path d='M9.92187 12.1875C10.502 12.1875 11.0584 11.957 11.4687 11.5468C11.8789 11.1366 12.1094 10.5802 12.1094 10C12.1094 9.93595 12.1064 9.87247 12.101 9.80977L9.73163 12.1791C9.79433 12.1846 9.85761 12.1875 9.92187 12.1875ZM17.1625 3.2336L16.3281 2.40001C16.2988 2.37073 16.2591 2.35428 16.2177 2.35428C16.1762 2.35428 16.1365 2.37073 16.1072 2.40001L13.9721 4.53575C12.7942 3.9338 11.4702 3.63282 9.99999 3.63282C6.24608 3.63282 3.4453 5.5879 1.59765 9.49805C1.52338 9.65531 1.48486 9.82707 1.48486 10.001C1.48486 10.1749 1.52338 10.3467 1.59765 10.5039C2.33593 12.059 3.22493 13.3054 4.26464 14.2432L2.19843 16.3086C2.16915 16.3379 2.1527 16.3776 2.1527 16.4191C2.1527 16.4605 2.16915 16.5002 2.19843 16.5295L3.03222 17.3633C3.06152 17.3926 3.10124 17.409 3.14267 17.409C3.18409 17.409 3.22382 17.3926 3.25312 17.3633L17.1625 3.45469C17.177 3.44018 17.1885 3.42295 17.1964 3.40398C17.2043 3.38501 17.2083 3.36468 17.2083 3.34415C17.2083 3.32361 17.2043 3.30328 17.1964 3.28431C17.1885 3.26534 17.177 3.24811 17.1625 3.2336ZM6.48437 10C6.48431 9.40627 6.63805 8.82264 6.93059 8.30597C7.22314 7.78931 7.64452 7.35723 8.15369 7.05182C8.66286 6.74642 9.24245 6.5781 9.836 6.56327C10.4296 6.54844 11.0168 6.6876 11.5406 6.9672L10.591 7.9168C10.208 7.79418 9.79869 7.77941 9.40788 7.87412C9.01707 7.96883 8.6599 8.16936 8.37556 8.4537C8.09122 8.73804 7.89069 9.09521 7.79598 9.48602C7.70127 9.87683 7.71604 10.2862 7.83866 10.6691L6.88905 11.6188C6.62257 11.1209 6.48354 10.5647 6.48437 10Z' />
                <path d='M18.4024 9.49609C17.7149 8.04818 16.8966 6.86751 15.9475 5.9541L13.1322 8.76953C13.3695 9.38987 13.4222 10.0657 13.2837 10.7152C13.1452 11.3648 12.8216 11.9604 12.352 12.4301C11.8823 12.8997 11.2867 13.2233 10.6371 13.3618C9.98755 13.5003 9.31176 13.4477 8.69142 13.2104L6.30353 15.5982C7.41134 16.1109 8.6435 16.3672 10 16.3672C13.7539 16.3672 16.5547 14.4121 18.4024 10.502C18.4766 10.3447 18.5152 10.1729 18.5152 9.99902C18.5152 9.82511 18.4766 9.65335 18.4024 9.49609Z' />
              </>
            )
          }
        </svg>
      )}

      {error && <Error>{error}</Error>}
    </Container>
  )
}

Input.defaultProps = {
  error: '',
  type: 'text',
}

export default Input
