import * as colors from 'constants/colors'
import styled from "styled-components"

export const Title = styled.h2`
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 10px;
  text-align: center;
  color: ${colors.primary01};
`

export const Container = styled.div`

`

export const Header = styled.section`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`

export const SearchContainer = styled.section<{ isUtp : boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  gap: 5px;
  margin: 20px 0 10px;
  
  input{
    height: 40px;
  }

  @media (min-width: 769px) {
    grid-template-columns: ${({ isUtp }) => isUtp ? '1fr 1fr 1fr 1fr auto auto': '1fr 1fr 1fr auto'};
  }
`

export const TableContainer = styled.section`
  overflow: auto;
  min-width: 200px;
`

export const Table = styled.table`
  width: 100%;
  border: 1px solid #bfbfbf;
  border-collapse: collapse;
  border-radius: 10px;
  td, th{
    padding: 10px;
    border: 1px solid #bfbfbf;
    border-collapse: collapse;
    vertical-align: middle;
  }
`

export const ButtonSchools = styled.button`
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  background-color: ${colors.primary03};
`

export const ButtonPdf = styled.section`
  display: grid;
  place-items: center;
  padding: 5px 10px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${colors.primary01};
  fill: ${colors.neutral02};
  &:hover{
    background-color: #ed7e4f;
  }
`

export const ButtonExcel = styled.section<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  color: #fff;
  font-weight: 600;
  background: ${({ disabled }) => disabled ? '#cfcfcf' : '#1c8320'};
  fill: ${colors.neutral02};
  &:hover{
    background-color: ${({ disabled }) => disabled ? '#cfcfcf' : '#1c8320'};
  }
`

export const ButtonExit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  color: #fff;
  background: red;
  cursor: pointer;
`

export const PaginationContainer = styled.section`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  button{
    width: fit-content;
  }
`

export const Info = styled.div`
  display: flex;
  font-size: 14px;
  background-color: #e1edff;
  color: #6083b7;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
`

export const ButtonBack = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  color: #fff;
  background: ${colors.primary01};
  cursor: pointer;
`