import { ILayout } from "./props"
import { Container, Title } from "./styles"

const Layout = ({
  title,
  children
}: ILayout) => {
  return (
    <>
      {/* <Header /> */}
      {title && (
        <Title>
          <h1>{title}</h1>
        </Title>
      )}
      <Container>
        {children}
      </Container>
    </>
  )
}

export default Layout