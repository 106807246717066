import { Button, Input } from 'components'
import * as links from 'constants/links'
import { TStatusMP, TTypeProcesses } from 'constants/types'
import { TypeProcesses } from 'data/types'
import useDebounce from 'hooks/useDebounce'
import useToggle from 'hooks/useToggle'
import { IPay } from 'interfaces'
import { ButtonBack } from "pages/Process/styled"
import { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { handleCopyClipboard } from 'tools/functions'
import { Details, ModalConfig } from './childrens'
import { paymentsListPaging } from './services'
import { ButtonCopy, Container, ContainerDetail, Header, PaginationContainer, SearchContainer, Table, TableContainer, Title } from "./styled"

const Pay = () => {
  const { id } = useParams<{
    id: TTypeProcesses
  }>();
  const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [pays, setPays] = useState<IPay[]>([])
  const [pay, setPay] = useState<IPay>({} as IPay)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [filters, setFilters] = useState({
    search: '',
    startDate: '',
    endDate: '',
    origin: id,
  })
  const searchDebounce = useDebounce(filters.search, 1000)
  const [showModalConfig, toggleShowModal] = useToggle(false)
  const [showModalDetails, toggleShowModalDetails] = useToggle(false)
  const process: string = TypeProcesses.find((process) => process.id === id)?.name || ''

  const returnStatus = (status: TStatusMP) => {
    switch (status) {
      case 'approved':
        return 'Aprobado ✅'

      case 'pending':
      case 'in_process':
      case 'in_mediation':
        return 'Pendiente'

      case 'refunded':
        return 'Reembolsado 💸'

      case 'rejected':
      case 'cancelled':
      case 'charged_back':
      case 'authorized':
        return 'Rechazado ❌'
    }
  }

  const handleCopy = (link: string) => {
    const url = `${links.generateProcessLink}${link}`
    handleCopyClipboard(url)
  }

  const handleDetails = (pay: IPay) => {
    setPay(pay)
    toggleShowModalDetails()
  }

  useEffect(() => {
    paymentsListPaging(
      setLoading,
      setPays,
      page,
      setTotalPages,
      setTotal,
      filters
    )
  }, [page, searchDebounce, filters.startDate, filters.endDate])

  return (
    <>
      <Container>
        <Header>
          <ButtonBack onClick={() => history.push(links.dashboard)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="220" viewBox="0 0 1024 1024">
              <path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z" />
            </svg>
          </ButtonBack>
          <Title>Lista de pagos de {process}</Title>
        </Header>

        <SearchContainer>
          <Input
            type="search"
            placeholder="Buscar por email"
            label="Buscar"
            value={filters.search}
            onChange={(search) => setFilters({ ...filters, search })}
          />

          <Input
            type="date"
            label="Desde"
            maxDate={filters.endDate}
            value={filters.startDate}
            onChange={(startDate) => setFilters({ ...filters, startDate })}
          />

          <Input
            type="date"
            label="Hasta"
            minDate={filters.startDate}
            value={filters.endDate}
            onChange={(endDate) => setFilters({ ...filters, endDate })}
          />

          <Button
            bgColor='brown'
            value='Configuración'
            onClick={toggleShowModal}
          />
        </SearchContainer>

        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Id</th>
                <th>E-mail</th>
                <th>Estado</th>
                <th>Monto</th>
                <th>Completado</th>
                <th>Fecha</th>
                <th>Detalle</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ? (
                  <tr>
                    <td colSpan={9}>cargando...</td>
                  </tr>
                ) : (
                  <>
                    {
                      pays.length === 0 && (
                        <tr>
                          <td colSpan={9}>No hay pagos registrados</td>
                        </tr>
                      )
                    }
                    {pays.length > 0 && pays.map((pay) => (
                      <tr key={pay.id}>
                        <td>{pay.id}</td>
                        <td>{pay.email}</td>
                        <td>{returnStatus(pay.status)}</td>
                        <td>S/.{pay.amount}</td>
                        <td>{pay.is_used ? 'Si' : 'No'}</td>
                        <td>
                          {pay.created_at
                            ? new Date(pay.created_at).toLocaleString()
                            : ''
                          }
                        </td>
                        <td>
                          <ContainerDetail>
                            <svg
                              onClick={() => handleDetails(pay)}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              className='cursor-pointer'>
                              <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z" />
                              <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z" />
                            </svg>
                            {(pay.uuid && pay.status === 'approved') &&
                              <ButtonCopy
                                onClick={() => handleCopy(pay.uuid)}
                              >
                                Copiar link
                              </ButtonCopy>
                            }
                          </ContainerDetail>
                        </td>
                      </tr>
                    ))}
                  </>
                )
              }
            </tbody>
          </Table>
        </TableContainer>

        {
          !loading && pays.length > 0 && (
            <PaginationContainer>
              <p style={{ marginRight: 'auto' }}>
                Total: {total}
              </p>

              <Button
                onClick={() => page > 1 && setPage(page - 1)}
                value="Anterior"
              />
              <span>{page} / {totalPages}</span>
              <Button
                onClick={() => page < totalPages && setPage(page + 1)}
                value="Siguiente"
              />
            </PaginationContainer>
          )
        }
      </Container>

      <ModalConfig
        open={showModalConfig}
        onClose={toggleShowModal}
      />

      <Details
        open={showModalDetails}
        onClose={toggleShowModalDetails}
        pay={pay}
      />
    </>
  )
}

export default Pay