import { Toast } from 'components';
import { IAuth, IUser } from 'interfaces';
import React, { createContext, useContext, useState } from 'react';
import { postAuth, postAuthValidate } from './api';

const AuthContext = createContext<IAuth | undefined>(undefined);

export const useAuth = (): IAuth => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser utilizado dentro de un AuthProvider');
  }
  return context;
};

const AuthProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);

  const login = async (userData: IUser) => {
    try {
      const response = await postAuth(userData)

      if (response?.response !== 'success') {
        throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
      }

      localStorage.setItem('token', response?.token || '')
      setLoggedIn(true);
      setUser(userData);

    } catch (error: any) {
      setLoggedIn(false);
      setUser(null);
      console.log(error)
      console.log(error.message)
      Toast({
        type: 'error',
        message: (
          error.message === 'Load failed' ||
          error.message === 'NetworkError when attempting to fetch resource.'
        ) ? 'Intente nuevamente' : error.message
      })
    } finally {
    }
  };

  const loginWithToken = async (setLoading: Function) => {
    try {
      setLoading(true)
      const response = await postAuthValidate()

      if (response?.response !== 'success') {
        throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
      }

      setLoggedIn(true);
      setUser(response?.user || null);

    } catch (error: any) {
      setLoggedIn(false);
      setUser(null);
      console.log(error)
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    setLoggedIn(false);
    setUser(null);
  };

  const authValues: IAuth = {
    loggedIn,
    user,
    login,
    logout,
    loginWithToken
  };

  return (
    <AuthContext.Provider value={authValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;