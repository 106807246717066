import styled from "styled-components";

export const Container = styled.div`
  max-width: 1170px;
  padding: 30px 30px 50px 30px;
  box-shadow: 0px 0 1px rgba(0,0,0,.18), 0px 0 1px rgba(0,0,0,.18);
  min-width: 240px;
  margin: 0 auto;
`

export const Title = styled.section`
  padding: 10px 2% 10px;
  max-width: 100%;
  border-bottom: 1px solid rgba(0,0,0,.1);
  h1{
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    line-height: 1.4;
  }
`