import * as colors from 'constants/colors'
import styled from 'styled-components'
import IButton from './props'

export const ComponentButton = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
  padding: 10px;
  background-color: ${({ outline, bgColor }) => (outline ? 'transparent' : bgColor ? bgColor : colors.primary01)};
  border: 1px solid ${({ bgColor }) => (bgColor ? bgColor :colors.primary01)};
  border-radius: ${({ round }) => round ? '50%' : '4px'};
  color: ${colors.neutral02};
  font-weight: 500;
  width: ${({ round }) => round ? `48px` : '100%'};
  height: 40px;
  cursor: ${({ loading }) => loading ? 'auto' : 'pointer'};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  &:hover{
    opacity: ${({ loading }) => (loading ? '1' : '0.7')};
    color: ${colors.neutral02};
    fill: ${colors.neutral02};
  }
`
