export const TypeProcesses: {
  id: string,
  number: string,
  name: string,
}[] = [
    {
      id: 'mga',
      number: '1',
      name: 'MGA',
    },
    {
      id: 'self-pay',
      number: '2',
      name: 'MGA auto-pago',
    },
    {
      id: 'usat',
      number: '3',
      name: 'USAT',
    },
    {
      id: 'utp',
      number: '4',
      name: 'UTP',
    },
  ]

export const TypePays: {
  id: string,
  number: string,
  name: string,
}[] = [
    {
      id: 'self-pay',
      number: '1',
      name: 'Test MGA auto-pago',
    }
  ]