import styled from 'styled-components'

export const ComponentSelect = styled.select`
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #000;
  color: ${(props) => props.color};
  height: 40px;
  padding: 10px;
  width: 100%;
`

export const Container = styled.div`
  position: relative;
  padding: 10px 0 0 0;
`

export const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateY(-50%);
  padding: 0 4px;
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  color: ${({ theme }) => theme.inputLabelColor};
`
