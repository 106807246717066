import { API } from "constants/envs"

export const getPaymentsPaging = async (
  page: number,
  filters: { search: string, startDate: string, endDate: string }
) => {
  let url = `${API}/mp/list?page=${page}&search=${filters.search}`;

  if (filters.startDate) {
    const date = new Date(filters.startDate)
    const dateISO = date.toISOString()
    const startDate = dateISO.split('T')[0] + 'T05:00:00.000Z'

    url += `&startDate=${startDate}`
  }

  if (filters.endDate) {
    const endDate = new Date(filters.endDate)
    endDate.setDate(endDate.getDate() + 1)
    endDate.setHours(23, 59, 59)
    const dateISO = endDate.toISOString()

    url += `&endDate=${dateISO}`
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}